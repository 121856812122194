export const save = (field, value) => {
  const json = JSON.stringify(value)
  localStorage.setItem(field, json)
}

export const load = (field) => {
  try {
    const json = localStorage.getItem(field)
    if (json) {
      return JSON.parse(json)
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}
